import { Col, Divider, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { EyeIcon } from '../../../assets/svg';
import {
  defaultDateFormat,
  GUTTER_VARIATIONS,
  NA_PLACEHOLDER_TEXT,
  ROUTES,
} from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import { EProceedingDetailProps } from '../graphql/clients.type';
const { Paragraph, Text } = Typography;

dayjs.extend(relativeTime);

const EProceedingDetail = ({ details }: EProceedingDetailProps) => {
  const { navigate } = useRouter();
  return (
    <>
      {details && details.length > 0 ? (
        details.map((detail, index) => (
          <div key={index}>
            <div className="d-flex gap-24">
              <div className="gap-16 full-width">
                <Row>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                  >
                    {detail?.description}
                  </Paragraph>
                </Row>
                <Row
                  gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
                  className="mt-16"
                >
                  <Col xs={8} xl={8} className="d-grid">
                    <Text className="title">Notice Reference Id</Text>
                    <Text className="value">
                      {detail?.noticeReferenceId
                        ? detail?.noticeReferenceId
                        : NA_PLACEHOLDER_TEXT}
                    </Text>
                  </Col>
                  <Col xs={8} xl={8} className="d-grid">
                    <Text className="title">Assessment Year</Text>
                    <Text className="value">
                      {detail?.assessmentYear || NA_PLACEHOLDER_TEXT}
                    </Text>
                  </Col>
                  <Col xs={8} xl={8} className="d-grid">
                    <Text className="title">Due Date</Text>
                    <Text className="value text-size-12">
                      {detail?.responseDueDate
                        ? dayjs(detail?.responseDueDate).format(
                            defaultDateFormat,
                          )
                        : NA_PLACEHOLDER_TEXT}
                    </Text>
                  </Col>
                </Row>
              </div>
              <div
                className="eye-action pointer"
                onClick={() =>
                  navigate(
                    `/${ROUTES?.DIRECT_TAX}/${ROUTES?.TAX_LITIGATION}/${detail?._id}${ROUTES.RESPONSE}`,
                  )
                }
              >
                <EyeIcon />
              </div>
            </div>
            {index < details.length - 1 && <Divider className="m-16" />}
          </div>
        ))
      ) : (
        <p className="empty text-center">There are no record to display</p>
      )}
    </>
  );
};

export default EProceedingDetail;
