import { useQuery } from '@apollo/client';
import { Button, Card, Col, Row, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Checks, FilePDF } from '../../assets/svg';
import { GUTTER_VARIATIONS } from '../../common/constants';
import CommonModal from '../../components/modals/CommonModal';
import MyBreadcrumb from '../../components/MyBreadcrumb';
import useRouter from '../../hooks/useRouter';
import { MetaData } from '../../types/common.type';
import './FiledReturn.less';
import { ITR_STATUS_LIST } from './graphql/Queries';

const { Text } = Typography;

const FiledReturnStatus: React.FC = () => {
  const { navigate, params } = useRouter();
  const { pan } = params;
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data: { itr } = {} } = useQuery(ITR_STATUS_LIST, {
    variables: {
      where: {
        id: pan,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  return (
    <div className="container">
      <div className="mt-16 mb-16 d-flex justify-between">
        <MyBreadcrumb />
        <Text className="title">
          {itr?.assessee?.username} | {itr?.assessee?.name}
        </Text>
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading pb-16">ITR Filed Return Status</Text>
      </div>
      <Card>
        {itr?.metadata?.map((item: MetaData, index: number) => (
          <Row
            gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
            key={index}
            className="mb-8"
          >
            <Col xs={24} lg={24} xl={24}>
              <Card>
                <div className="submission-card d-flex justify-between align-center">
                  <div className="d-flex align-center">
                    <span className="check-icon">
                      <Checks />
                    </span>
                    <div className="d-grid detail">
                      <Text className="items-title dark-text">
                        {item?.status}
                      </Text>
                      <Text className="date">{item?.date}</Text>
                    </div>
                  </div>
                  {index === 0 &&
                    itr.attachments &&
                    itr.attachments.length > 0 && (
                      <div className="files-detail d-flex">
                        <span className="mr-8">
                          <FilePDF />
                        </span>
                        <Link
                          className="color-blue multi-pdf"
                          to={itr?.attachments[0]?.url ?? ''}
                          download={itr?.attachments[0]?.url}
                          target="_blank"
                        >
                          {itr?.attachments[0]?.name}
                        </Link>
                        {itr?.attachments?.length > 1 && (
                          <Button
                            type="link"
                            className="underline-btn"
                            onClick={() => setOpenModal(true)}
                          >
                            View all
                          </Button>
                        )}
                      </div>
                    )}
                </div>
              </Card>
            </Col>
          </Row>
        ))}
        <Button
          type="default"
          className="float-right"
          onClick={() => navigate(-1)}
        >
          Back To List
        </Button>
      </Card>
      <div className="attachments">
        <CommonModal
          className="h-512"
          title="Attachments"
          open={openModal}
          footer={false}
          closable={true}
          onCancel={() => setOpenModal(false)}
        >
          <div className="d-flex gap-16 flex-vertical">
            {itr?.attachments?.map((file, index: number) => (
              <div className="d-flex align-center" key={index}>
                <span className="mr-8 d-flex">
                  <FilePDF />
                </span>
                <Link
                  className="color-blue"
                  to={file?.url ?? ''}
                  download={file?.url}
                  target="_blank"
                >
                  {file?.name}
                </Link>
              </div>
            ))}
          </div>
        </CommonModal>
      </div>
    </div>
  );
};

export default FiledReturnStatus;
