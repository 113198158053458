import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import { Key, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Export,
  EyeIcon,
  FilePDF,
  Icon,
  Minus,
  Pencil,
  SelectDropdownIcon,
} from '../../assets/svg';
import axiosInstance from '../../common/axiosInstance';
import {
  AI_TOOLTIP_MESSAGE,
  defaultDateFormat,
  DURATION_REDIRECT_TYPE,
  DURATION_TYPE,
  EMPTY_STATE,
  E_PROCEEDINGS_STATUS,
  E_PROCEEDINGS_TYPE,
  ITR_FILL_START_YEAR,
  LIMIT,
  MESSAGE,
  ROUTES,
} from '../../common/constants';
import {
  downloadCsv,
  formItemProps,
  formValidatorRules,
  generateYearOptions,
} from '../../common/utils';
import CommonPagination from '../../components/CommonPagination';
import CommonSearch from '../../components/CommonSearch';
import TableComponent from '../../components/CommonTable';
import StatusTag from '../../components/CommonTag';
import InfiniteSelect from '../../components/InfiniteSelect';
import CommonModal from '../../components/modals/CommonModal';
import MyBreadcrumb from '../../components/MyBreadcrumb';
import useRouter from '../../hooks/useRouter';
import { FormValues, SelectedRowKeysState } from '../../types/common.type';
import {
  DurationType,
  EProceeding,
  EProceedingsFilter,
  EProceedingStatus,
  EProceedingType,
  InputMaybe,
  SortOrder,
} from '../../__generated__/graphql';
import { ASSESSEES_LIST } from '../filed-return/graphql/Queries';
import { UPDATE_E_PROCEEDING_STATUS } from './graphql/Mutation';
import { E_PROCEEDINGS_LIST } from './graphql/Queries';
const { Text } = Typography;
const { required } = formValidatorRules;
const { RangePicker } = DatePicker;

const initialFilters = {
  search: '',
  limit: LIMIT,
};

const initialValue = {
  filters: {
    assesseeId: null,
    assessmentYear: null,
    type: null,
    duration: null,
    period: {
      end: '',
      start: '',
    },
    status: null,
  },
};

const TaxLitigation = () => {
  const [statusForm] = Form?.useForm();
  const [filterForm] = Form?.useForm();
  const {
    navigate,
    params: { id },
  } = useRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysState>(
    {},
  );
  const [listFilter, setListFilter] = useState(initialFilters);
  const [statusFormHandle, setStatusFormHandle] = useState(true);
  const filters = Form.useWatch('filters', filterForm) ?? initialValue.filters;
  const totalSelectedRows = Object?.values(selectedRowKeys)?.flat();
  const updateBulkStatus = Form.useWatch('statusForm', statusForm);
  const [searchParams] = useSearchParams();
  const searchValue =
    searchParams.get('value') ||
    searchParams.get('duration') ||
    searchParams.get('client');
  const askAI = process.env.REACT_APP_ASK_AI_REDIRECT_URL!;

  const filter: InputMaybe<EProceedingsFilter> = useMemo(
    () => ({
      assesseeId: filters?.assesseeId?.value ?? id,
      assessmentYear: filters?.assessmentYear,
      type: filters?.type,
      ...(filters?.period?.[0] &&
        filters?.period?.[1] && {
          period: {
            start: filters?.period?.[0],
            end: filters?.period?.[1],
          },
        }),
      status: filters?.status,
      skip: (currentPage - 1) * LIMIT,
      search: listFilter.search,
      limit: listFilter.limit,
      duration: filters?.duration,
    }),
    [filters, listFilter, currentPage],
  );

  useEffect(() => {
    const status = DURATION_REDIRECT_TYPE?.find(
      (item) => item?.label === searchValue,
    );
    if (searchValue) {
      filterForm.setFieldsValue({
        filters: {
          duration: status?.value,
          status:
            searchParams.get('duration') === 'pending'
              ? EProceedingStatus.Pending
              : null,
          ...(searchParams.get('client') && {
            assesseeId: {
              value: searchParams.get('client') || null,
              label: searchParams.get('clientlabel') || null,
            },
          }),
        },
      });
    }
  }, []);

  const {
    data: { eProceedings = {} } = {},
    loading,
    refetch,
  } = useQuery(E_PROCEEDINGS_LIST, {
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_E_PROCEEDING_STATUS,
    {
      onCompleted: () => {
        refetch();
        setSelectedRowKeys({});
        statusForm.resetFields();
        setIsModalOpen(false);
      },
    },
  );

  const handlePagination = (current: number) => {
    setCurrentPage(current);
  };

  const handleClear = () => {
    filterForm?.resetFields();
  };

  const rowSelection: TableProps<EProceeding>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys?.[currentPage],
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys((prev) => ({
        ...prev,
        [currentPage]: newSelectedRowKeys,
      }));
    },
    ...(selectedRowKeys[currentPage]?.length && {
      columnTitle: () => (
        <span
          className="d-flex pointer"
          onClick={() => {
            setSelectedRowKeys((prev) => ({
              ...prev,
              [currentPage]: [],
            }));
          }}
        >
          <Minus />
        </span>
      ),
    }),
  };

  const cancelModal = () => {
    setIsModalOpen(false);
    setStatusFormHandle(true);
    statusForm.resetFields();
  };

  const handleResponse = (record: EProceeding) => {
    if (record) {
      navigate(`${record?._id}${ROUTES.RESPONSE}`);
    }
  };

  const columns: ColumnType<EProceeding>[] = [
    {
      title: 'PAN',
      dataIndex: 'panNumber',
      key: 'panNumber',
      fixed: 'left',
      render: (_, recode) => recode?.assessee?.username,
    },
    {
      title: 'Name of Assessee',
      dataIndex: 'assessee-name',
      key: 'assessee-name',
      fixed: 'left',
      render: (_, recode) => {
        return recode?.assessee?.name;
      },
    },
    {
      title: 'A.Y.',
      dataIndex: 'assessmentYear',
      key: 'noticeId',
      fixed: 'left',
    },
    {
      title: 'F.Y.',
      dataIndex: 'financialYear',
      key: 'financialYear',
    },
    {
      title: 'Reference ID',
      dataIndex: 'noticeReferenceId',
      key: 'noticeReferenceId',
    },
    {
      title: 'Proceedings Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Issued Date',
      dataIndex: 'issuedOn',
      key: 'issuedOn',
      render: (issuedOn) =>
        issuedOn ? dayjs(issuedOn).format(defaultDateFormat) : '-',
    },
    {
      title: 'Response Due Date',
      dataIndex: 'responseDueDate',
      key: 'responseDueDate',
      render: (responseDueDate) =>
        responseDueDate
          ? dayjs(responseDueDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Proceeding Closure Date',
      dataIndex: 'proceedingCloseDate',
      key: 'proceedingCloseDate',
      render: (proceedingClosureDate) =>
        proceedingClosureDate
          ? dayjs(proceedingClosureDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Proceeding Closure Order',
      dataIndex: 'proceedingCloseOrder',
      key: 'proceedingCloseOrder',
      render: (proceedingClosureDate) => proceedingClosureDate ?? '-',
    },
    {
      title: 'Proceeding Limitation Date',
      dataIndex: 'proceedingLimitationDate',
      key: 'proceedingLimitationDate',
      render: (proceedingLimitationDate) =>
        proceedingLimitationDate
          ? dayjs(proceedingLimitationDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Email',
      dataIndex: ['letterPdf', 'from'],
      key: 'email',
    },
    {
      title: 'Notice u/s',
      dataIndex: 'noticeUs',
      key: 'noticeUs',
      render: (noticeUs) => (!isEmpty(noticeUs) ? noticeUs : '-'),
      ellipsis: true,
    },
    {
      title: 'Document Reference ID',
      dataIndex: 'documentRefId',
      key: 'documentRefId',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Proceeding Open Date',
      dataIndex: 'proceedingOpenDate',
      key: 'proceedingOpenDate',
      render: (proceedingOpenDate) =>
        proceedingOpenDate
          ? dayjs(proceedingOpenDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: 'Type Of Proceeding',
      dataIndex: 'type',
      key: 'type',
      render: (remarks) => {
        let text = '';
        switch (remarks) {
          case EProceedingType.Fya:
            text = 'For your action';
            break;
          case EProceedingType.Fyi:
            text = 'For your information';
            break;
          default:
            text = '';
        }
        return text;
      },
    },
    {
      title: 'Remarks',
      dataIndex: 'remarkByAdmin',
      key: 'remarkByAdmin',
      render: (remarks) => (remarks ? remarks : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      fixed: 'right',
      key: 'status',
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record: EProceeding) => {
        const pdf = record?.letterPdf?.attachments?.[0]?.url;
        return (
          <Space>
            <Link
              className={`${!pdf ? 'pointer-not-allowed' : 'pointer'}`}
              to={pdf ?? ''}
              {...(pdf && {
                download: { pdf },
                target: '_blank',
              })}
            >
              <FilePDF {...(!pdf && { color: '#C6C6C6' })} />
            </Link>
            <span
              key="edit"
              onClick={() => {
                setIsModalOpen(true);
                statusForm.setFieldsValue({
                  statusForm: {
                    status: record.status,
                    remark: record.remarks,
                    id: [record._id],
                  },
                });
              }}
              className="pointer"
            >
              <Pencil />
            </span>
            {!searchParams.get('value') && (
              <span onClick={() => handleResponse(record)} className="pointer">
                <EyeIcon key="view" />
              </span>
            )}
            <Tooltip placement="top" title={AI_TOOLTIP_MESSAGE}>
              <Button
                type="default"
                icon={<Icon />}
                className="d-flex align-center ask-ai-btn pointer p-0"
                onClick={() => window.open(askAI)}
              >
                Ask AI
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const exportCsv = async () => {
    const response = await axiosInstance.post('/v1/eproceeding/export-csv', {
      filter: {
        ...omit(filter, ['limit', 'skip']),
      },
    });
    downloadCsv(response);
  };

  const updateStateForm = (value: FormValues) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.id,
        },
        data: {
          remarkByAdmin: value?.statusForm?.remark,
          status: value?.statusForm?.status,
        },
      },
    });
    setStatusFormHandle(true);
  };

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb />
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">Income Tax Notice</Text>
        <CommonSearch
          handleChangeSearch={(val) => {
            setListFilter((prev) => ({ ...prev, search: val }));
            setCurrentPage(1);
          }}
          iconPlacement="right"
          allowClear
        />
      </div>
      <div className="d-flex mt-16 gap-16">
        <Button
          type="primary"
          className="d-flex align-center"
          icon={<Export />}
          onClick={() => exportCsv()}
        >
          Export To CSV
        </Button>
        <Button
          type="default"
          className="d-flex align-center"
          onClick={async () => {
            setIsModalOpen(true);
            statusForm.setFieldsValue({
              statusForm: {
                id: totalSelectedRows,
              },
            });
          }}
          disabled={!(totalSelectedRows.length > 0)}
        >
          Bulk Update Status
        </Button>
      </div>
      <Card className="mt-16">
        <Form
          form={filterForm}
          layout="vertical"
          onValuesChange={() => setCurrentPage(1)}
        >
          <div className="filters d-flex align-center gap-16">
            <Form.Item
              name={['filters', 'assesseeId']}
              label="Select Client"
              className="select pointer"
            >
              <InfiniteSelect
                query={ASSESSEES_LIST}
                variableSelector={({ skip, limit, search }) => ({
                  filter: {
                    skip,
                    limit,
                    search,
                  },
                  sort: {
                    sortBy: SortOrder.Desc,
                  },
                })}
                dataSelector={({ assessees }) => {
                  return (
                    assessees?.data?.map((item) => ({
                      label: item?.username ?? '',
                      value: item?._id ?? '',
                    })) ?? []
                  );
                }}
                countSelector={({ assessees }) => assessees?.count ?? 0}
                allowSearch
                placeholder="PAN, username"
                allowClear
                fetchPolicy="network-only"
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'assessmentYear']}
              label="Assessment Year"
              className="select"
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                placeholder="All"
                options={generateYearOptions(ITR_FILL_START_YEAR)}
                allowClear
                showSearch
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'type']}
              label="Type Of Proceeding"
              className="select"
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                placeholder="All"
                options={E_PROCEEDINGS_TYPE}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'status']}
              label="Status"
              className="select"
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                placeholder="All"
                options={E_PROCEEDINGS_STATUS}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name={['filters', 'duration']}
              label="Duration"
              className="select"
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                placeholder="All"
                options={DURATION_TYPE}
                allowClear
              />
            </Form.Item>
            {filter?.duration === DurationType.Custom && (
              <Form.Item
                name={['filters', 'period']}
                label="Period"
                className="select"
              >
                <RangePicker allowClear />
              </Form.Item>
            )}
            <Form.Item className="d-flex align-end align-self-end">
              <Button
                type="link"
                onClick={handleClear}
                disabled={
                  !(
                    filters.assesseeId ||
                    filters.assessmentYear ||
                    filters.duration ||
                    filters.status ||
                    filters.type
                  )
                }
              >
                Clear All
              </Button>
            </Form.Item>
          </div>
        </Form>
        <TableComponent<EProceeding>
          columns={columns}
          dataSource={eProceedings?.data as EProceeding[]}
          pagination={false}
          rowSelection={rowSelection}
          rowKey="_id"
          scroll={{ x: 'max-content' }}
          locale={EMPTY_STATE}
          loading={loading}
        />
        {eProceedings?.count ? (
          <CommonPagination
            count={eProceedings?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
            onValuesChange={() => setStatusFormHandle(false)}
          >
            <Form.Item
              label="Status"
              name={['statusForm', 'status']}
              rules={[{ ...required, message: MESSAGE?.required }]}
              normalize={formItemProps.normalize}
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                options={E_PROCEEDINGS_STATUS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item label="Remark" name={['statusForm', 'remark']}>
              <Input />
            </Form.Item>
            <Form.Item label="Remark" name={['statusForm', 'id']} hidden>
              <Select suffixIcon={<SelectDropdownIcon />} mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
              disabled={statusFormHandle ?? isEmpty(updateBulkStatus?.status)}
            >
              Update
            </Button>
          </Form>
        </div>
      </CommonModal>
    </div>
  );
};

export default TaxLitigation;
