import { Card, Divider } from 'antd';
import { ROUTES, ZERO_PLACEHOLDER_TEXT } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import { StatisticData } from '../../types/common.type';
import StatisticCard from '../StatiscticCard';

const Overview = ({ statisticData }: { statisticData: StatisticData[] }) => {
  const { navigate } = useRouter();
  const navigateToListPage = (items: string) => {
    ['failedLogin', 'allPan'].includes(items ?? '')
      ? navigate(`/${ROUTES.DIRECT_TAX}/${ROUTES.CLIENT}?duration=${items}`)
      : navigate(
          `/${ROUTES.DIRECT_TAX}/${ROUTES.TAX_LITIGATION}?duration=${items}`,
        );
  };

  return (
    <Card className="mb-16">
      <div className="statistic-cards">
        {statisticData.map((items, i) => {
          return (
            <>
              <StatisticCard
                key={items?.label}
                icon={items?.icon}
                label={items?.label}
                value={items?.value || ZERO_PLACEHOLDER_TEXT}
                onClick={() => navigateToListPage(items?.key || '')}
              />
              {i === 3 && <Divider className="m-16" />}
            </>
          );
        })}
      </div>
    </Card>
  );
};

export default Overview;
