import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { counselviseClient } from '../../apollo';
import {
  FETCH_USER_QUERY,
  SUBSCRIPTION_PLANS_QUERY,
} from '../../app/components/Queries';
import {
  ActiveBox,
  Calendar,
  CloseNotification,
  FilePDF,
  Files,
  HourGlassLaw,
  Pen,
  Pencil,
  RegularCalendar,
  Users,
  Warning,
} from '../../assets/svg';
import axiosInstance from '../../common/axiosInstance';
import {
  CURRENT_PAGE,
  defaultDateFormat,
  EMPTY_DUE_DATES_TEXT,
  E_PROCEEDINGS_STATUS,
  FEATURE_NOTICE_BOARD_DIRECT_TAX,
  GUTTER_VARIATIONS,
  LIMIT,
  MAX_LIST_NOTICE,
  MESSAGE,
  ROUTES,
  ZERO_PLACEHOLDER_TEXT,
} from '../../common/constants';
import { formItemProps, formValidatorRules } from '../../common/utils';
import TableComponent from '../../components/CommonTable';
import Overview from '../../components/dashboard/Overview';
import SyncCalendar from '../../components/dashboard/SyncCalendar';
import LoaderComponent from '../../components/LoaderComponent';
import CommonModal from '../../components/modals/CommonModal';
import useRouter from '../../hooks/useRouter';
import {
  assesseeStats,
  EProceedingCalendarData,
  FormValues,
  StatisticData,
} from '../../types/common.type';
import {
  CalenderData,
  EProceeding,
  EProceedingCalenderCountResponse,
  EProceedingsFilter,
} from '../../__generated__/graphql';
import { UPDATE_E_PROCEEDING_STATUS } from '../tax-litigation/graphql/Mutation';
import PlansCards from './component/PlansCards';
import './dashboard.less';
import { SubscriptionPlan } from './graphql/dashboard.type';
import { DISCONNECT_CALENDER } from './graphql/Mutation';
import {
  ASSESSEE_STATES,
  CALENDAR_COUNT,
  CURRENT_SYNC_CALENDAR,
  E_PROCEEDINGS,
} from './graphql/Queries';
const { Text } = Typography;
const { required } = formValidatorRules;

const initialFilter = {
  limit: MAX_LIST_NOTICE,
} as EProceedingsFilter;

const generateStatisticData = (
  assesseeStats: assesseeStats,
  iconColor?: string,
): StatisticData[] => {
  return [
    {
      icon: <Pen color={iconColor} />,
      label: 'All Notices',
      value: assesseeStats?.allNotices || ZERO_PLACEHOLDER_TEXT,
      key: 'allNotices',
    },
    {
      icon: <Files color={iconColor} />,
      label: 'Pending Notices',
      value: assesseeStats?.openNotices || ZERO_PLACEHOLDER_TEXT,
      key: 'pending',
    },
    {
      icon: <Calendar color={iconColor} />,
      label: 'Due Today',
      value: assesseeStats?.dueToday || ZERO_PLACEHOLDER_TEXT,
      key: 'dueToday',
    },
    {
      icon: <ActiveBox color={iconColor} />,
      label: '7 Days Due',
      value: assesseeStats?.oneWeekDue || ZERO_PLACEHOLDER_TEXT,
      key: '7DaysDue',
    },
    {
      icon: <HourGlassLaw color={iconColor} />,
      label: 'Last 24 Hours',
      value: assesseeStats?.last24Hours || ZERO_PLACEHOLDER_TEXT,
      key: 'last24Hours',
    },
    {
      icon: <RegularCalendar color={iconColor} />,
      label: 'OverDue',
      value: assesseeStats?.overDue || ZERO_PLACEHOLDER_TEXT,
      key: 'overDue',
    },
    {
      icon: <Users color={iconColor} />,
      label: 'Total PAN',
      value: assesseeStats?.totalPan || ZERO_PLACEHOLDER_TEXT,
      key: 'allPan',
    },
    {
      icon: <Warning color={iconColor} />,
      label: 'Failed Login',
      value: assesseeStats?.loginFailed || ZERO_PLACEHOLDER_TEXT,
      key: 'failedLogin',
    },
  ];
};

const Dashboard = () => {
  const dateFormat = 'YYYY/MM/DD';
  const startOfMonth = dayjs().startOf('month').format(dateFormat);
  const endOfMonth = dayjs().endOf('month').format(dateFormat);
  const [statusForm] = Form.useForm();
  const { navigate } = useRouter();
  const [plansModalOpen, setPlansModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDateResponses, setSelectedDataResponses] =
    useState<EProceedingCalendarData>();
  const [selecteddateRange, setSelecteddateRange] = useState({
    end: endOfMonth,
    start: startOfMonth,
  });
  const [searchParams] = useSearchParams();
  const [isNotificationVisible, setNotificationVisible] =
    useState<boolean>(false);

  const upcoming3Days = dayjs().add(3, 'day');
  const currentDate = dayjs();

  const { data: { currentUser } = {} } = useQuery(FETCH_USER_QUERY, {
    client: counselviseClient,
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.currentUser?.activeSubscriptions) {
        setNotificationVisible(true);
      }
    },
  });

  const { data: { assesseeStats } = {}, loading: loadStats } = useQuery(
    ASSESSEE_STATES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { data: { eProceedings } = {}, loading: loadNotices } = useQuery(
    E_PROCEEDINGS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      variables: {
        filter: initialFilter,
      },
    },
  );

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_E_PROCEEDING_STATUS,
    {
      onError() {},
      onCompleted: () => {
        statusForm.resetFields();
        setIsModalOpen(false);
      },
    },
  );
  const { data: { subscriptionPlans } = {}, loading: plansLoading } = useQuery(
    SUBSCRIPTION_PLANS_QUERY,
    {
      client: counselviseClient,
      onError() {},
      fetchPolicy: 'network-only',
    },
  );

  const { data: { eProceedingsCalenderCount } = {} } = useQuery(
    CALENDAR_COUNT,
    {
      skip: !endOfMonth && !startOfMonth,
      variables: {
        filter: {
          duration: {
            end: dayjs(selecteddateRange.end || endOfMonth, dateFormat),
            start: dayjs(selecteddateRange.start || startOfMonth, dateFormat),
          },
        },
      },

      onCompleted() {},
    },
  );

  const {
    data: { currentUserCalender } = {},
    loading: currentUserCalenderLoading,
    refetch,
  } = useQuery(CURRENT_SYNC_CALENDAR, {
    fetchPolicy: 'network-only',
  });

  const [disconnectCalander] = useMutation(DISCONNECT_CALENDER, {
    onCompleted: () => {
      refetch();
    },
  });

  const calanderDisconnected = () => {
    disconnectCalander();
  };

  const getSelectedRange = (dateString: [string, string]) => {
    setSelecteddateRange({
      start: dateString[0],
      end: dateString[1],
    });
  };

  const filteredData =
    eProceedings?.data?.filter((item) => {
      const responseDate = dayjs(item?.responseDueDate);
      return (
        responseDate.isAfter(currentDate) &&
        responseDate.isBefore(upcoming3Days)
      );
    }) || [];

  const filteredPlans = subscriptionPlans?.data?.filter((plan) =>
    plan?.features?.includes(FEATURE_NOTICE_BOARD_DIRECT_TAX),
  ) as SubscriptionPlan[];

  const latestSubscription = currentUser?.activeSubscriptions?.reduce(
    (latest, current) => {
      const latestDate = dayjs(latest?.createdAt || 0);
      const currentDate = dayjs(current?.createdAt || 0);
      return currentDate.isAfter(latestDate) ? current : latest;
    },
    null,
  );

  const activePlan =
    currentUser?.activeSubscriptions?.[0]?.features?.includes(
      FEATURE_NOTICE_BOARD_DIRECT_TAX,
    ) && latestSubscription?.subscriptionPlan
      ? latestSubscription
      : null;

  const updateStateForm = (value: FormValues) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.id,
        },
        data: {
          remarkByAdmin: value?.statusForm?.remark,
          status: value?.statusForm?.status,
        },
      },
    });
  };

  const getSelectedNotice = (notice: EProceedingCalendarData) => {
    setSelectedDataResponses(notice);
  };

  const syncCalanderWith = async () => {
    if (searchParams.get('scope')) {
      await axiosInstance.post(
        `/v1/direct-tax-calender/google-calendar-redirect?code=${searchParams.get('code')}`,
      );
    } else {
      await axiosInstance.post(
        `/v1/direct-tax-calender/outlook-redirect?code=${searchParams.get('code')}`,
      );
    }
    refetch();
    navigate(`/${ROUTES.DIRECT_TAX}`, { replace: true });
  };

  useEffect(() => {
    if (searchParams.get('code')) syncCalanderWith();
  }, [searchParams.get('code')]);

  const handleNavigation = () => {
    navigate(`/${ROUTES?.DIRECT_TAX}/${ROUTES?.TAX_LITIGATION}`);
  };

  const cancelModal = () => {
    setPlansModalOpen(false);
    setIsModalOpen(false);
    statusForm.resetFields();
  };

  const columns: ColumnType<EProceeding>[] = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      render: (_val, _, i) => i + 1 + LIMIT * (CURRENT_PAGE - 1),
    },
    {
      title: 'PAN',
      dataIndex: ['assessee', 'username'],
      key: 'username',
      fixed: 'left',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Name of Assessee',
      dataIndex: ['assessee', 'name'],
      key: 'name',
      render: (text) => text ?? '-',
    },
    {
      title: 'Reference ID',
      dataIndex: 'noticeReferenceId',
      key: 'noticeReferenceId',
      render: (text) => text ?? '-',
    },
    {
      title: 'A.Y.',
      dataIndex: 'assessmentYear',
      key: 'assessmentYear',
      render: (text) => text ?? '-',
    },
    {
      title: 'F.Y.',
      dataIndex: 'financialYear',
      key: 'financialYear',
      render: (text) => text ?? '-',
    },
    {
      title: 'Due Date',
      dataIndex: 'responseDueDate',
      key: 'responseDueDate',
      render: (responseDueDate) => {
        if (!responseDueDate) return '-';

        const formattedDate = dayjs(responseDueDate).format(defaultDateFormat);

        return <span className="notice-due-date">{formattedDate}</span>;
      },
    },
    {
      title: 'Action',
      fixed: 'right',
      render: (record) => (
        <Space>
          <Link
            className="pointer"
            to={record?.letterPdf?.attachments?.[0]?.url ?? ''}
            download={record?.letterPdf?.attachments?.[0]?.url}
            target="_blank"
          >
            <FilePDF />
          </Link>
          <span
            key="edit"
            onClick={() => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  status: record.status,
                  remark: record.remarks,
                  id: [record._id],
                },
              });
            }}
            className="pointer"
          >
            <Pencil />
          </span>
        </Space>
      ),
    },
  ];

  const loading = loadNotices || loadStats;

  return (
    <LoaderComponent spinning={loading}>
      <div className="dashboard-content-holder relative">
        {!activePlan &&
        isNotificationVisible &&
        !((currentUser?.userClient?.directTaxUsedCount ?? 0) >= 2) ? (
          <div className="plan-notification">
            <Text>
              Hey! Your “Free Trial” package has 2 free clients.{' '}
              <span onClick={() => setPlansModalOpen(true)} className="pointer">
                Get more clients
              </span>
            </Text>
            <span
              onClick={() => setNotificationVisible(false)}
              className="pointer"
            >
              <CloseNotification />
            </span>
          </div>
        ) : null}
        <div className="container">
          <div className="dashboard-content-wrapper mt-32 mb-32">
            <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
              <Col md={6} lg={6} className="section-1">
                <SyncCalendar
                  eProceedingsCalenderCount={
                    eProceedingsCalenderCount as EProceedingCalenderCountResponse
                  }
                  getSelectedNotice={getSelectedNotice}
                  selectedDateResponses={
                    selectedDateResponses as EProceedingCalendarData
                  }
                  getSelectedRange={getSelectedRange}
                  googleCalanderRedirectLink="/v1/direct-tax-calender/google-calendar-auth"
                  outlookCalanderRedirectLink="/v1/direct-tax-calender/outlook-login"
                  currentUserCalender={currentUserCalender as CalenderData}
                  currentUserCalenderLoading={currentUserCalenderLoading}
                  calanderDisconnected={calanderDisconnected}
                />
              </Col>
              <Col md={18} lg={18}>
                <Overview
                  statisticData={generateStatisticData(
                    assesseeStats as assesseeStats,
                    '#7A2976',
                  )}
                />
                <div className="mt-32">
                  <Text className="head">Nearing Due Dates</Text>
                  <TableComponent<EProceeding>
                    rowKey="_id"
                    columns={columns}
                    dataSource={filteredData as EProceeding[]}
                    className=" mt-8"
                    scroll={{ x: 'max-content' }}
                    locale={EMPTY_DUE_DATES_TEXT}
                  />
                  <Button
                    type="link"
                    className="underline-btn float-right"
                    onClick={handleNavigation}
                  >
                    View All
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <CommonModal
        open={plansModalOpen}
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
        centered={true}
        className="plans-container"
        width={1086}
      >
        <PlansCards
          activePlan={activePlan}
          plans={filteredPlans}
          loading={plansLoading}
          userClient={currentUser?.userClient}
        />
      </CommonModal>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label="Status"
              name={['statusForm', 'status']}
              rules={[{ ...required, message: MESSAGE?.required }]}
              normalize={formItemProps.normalize}
            >
              <Select
                options={E_PROCEEDINGS_STATUS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label="Remark"
              name={['statusForm', 'remark']}
              normalize={formItemProps.normalize}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Remark"
              name={['statusForm', 'id']}
              normalize={formItemProps.normalize}
              hidden
            >
              <Select mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
            >
              Update
            </Button>
          </Form>
        </div>
      </CommonModal>
    </LoaderComponent>
  );
};

export default Dashboard;
