import { Breadcrumb } from 'antd';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import useRouter from '../hooks/useRouter';

function MyBreadcrumb() {
  const {
    state: { breadcrumbTitle },
  } = useContext(AppContext);
  const {
    location: { pathname },
  } = useRouter();

  const pathSnippets = pathname?.split('/')?.filter((i: string) => i !== '');

  const breadcrumbItems = pathSnippets?.map((path, index) => {
    const url = `/${pathSnippets?.slice(0, index + 1)?.join('/')}`;

    const formatPath =
      path === 'direct-tax' ? 'dashboard' : path?.split('-')?.join(' ');

    const isLast = index === pathSnippets?.length - 1;

    const isId =
      /^[A-Za-z0-9]+$/.test(path) ||
      (/^[a-zA-Z0-9-]+$/.test(path) && path?.length > 20);

    return {
      key: url,
      title:
        isLast && breadcrumbTitle ? (
          <span className="active-link text-primary">{breadcrumbTitle}</span>
        ) : isId ? (
          <span className="non-clickable">{formatPath}</span>
        ) : (
          <Link to={url} className={`${isLast ? 'active-link' : 'ellipsis'}`}>
            {formatPath}
          </Link>
        ),
    };
  });

  return <Breadcrumb items={breadcrumbItems} className="my-breadcrumb" />;
}

export default MyBreadcrumb;
