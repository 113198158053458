import {
  ActivityLogStatus,
  DurationType,
  EProceedingStatus,
  EProceedingType,
  ItrFillingType,
  SyncLogStatus,
} from '../__generated__/graphql';

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  AUTHENTICATION: '/authentication',
  DIRECT_TAX: 'direct-tax',
  INDIRECT_TAX: '/indirect-tax',
  CLIENT: 'assessee-details',
  PAN_DASHBOARD: ':id/pan-dashboard',
  SYNC_LOG: 'sync-log',
  FILED_RETURN: 'filed-return-forms',
  FILED_RETURN_STATUS: ':pan/status',
  OTHER_FILED: 'other-filed-form',
  OTHER_FILED_DETAILS: ':id/detail',
  TAX_LITIGATION: 'e-proceeding-data',
  E_PROCESSING_RESPONSE: ':id/response',
  TRACK_RETURN_STATUS: '/track-return-status',
  NOTICES: '/notices',
  REFUND: '/refund',
  APPEALS: '/appeals',
  RESPONSE: '/response',
  DETAIL: 'detail',
  NOTICE_BOARD: 'notice-board',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  CLIENT: 'All Clients',
  FILED_RETURN: 'Filed Return Form',
  OTHER_FILED: 'Other Filed Form',
  TAX_LITIGATION: 'Tax Litigation',
  NOTICES: 'Notices',
  TRACK_RETURN_STATUS: 'Track-return-status',
  REFUND: 'Refund',
  APPEALS: 'Appeals',
};

export const LIMIT = 10;
export const MIN_LIMIT = 3;
export const MAX_LIST_NOTICE = 5;
export const CURRENT_PAGE = 1;

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const NA_PLACEHOLDER_TEXT = '-';
export const ZERO_PLACEHOLDER_TEXT = 0;

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  NOT_ACCEPTE_PHONE: /^(?!\d{10}$).+/,
  PAN_NUMBER: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
};
export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};
export const ACTIVATION = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const ACTIVE_STATUS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];
export const STATUS = [
  { label: 'Success', value: ActivityLogStatus?.Success },
  { label: 'Failed', value: ActivityLogStatus?.Failed },
  { label: 'Pending', value: ActivityLogStatus?.Pending },
];
export const SYNC_STATUS = [
  { label: 'All', value: SyncLogStatus?.All },
  { label: 'Success', value: SyncLogStatus?.Success },
  { label: 'Failed', value: SyncLogStatus?.Failed },
];
export const FILED_STATUS = [
  { label: 'All', value: '' },
  { label: 'Form verified', value: 'Form verified' },
  { label: 'Form verification', value: 'form verification' },
];
export const FILING_TYPE = [
  { label: 'All', value: ItrFillingType.All },
  { label: 'Original', value: ItrFillingType.Original },
  { label: 'Other', value: ItrFillingType.Others },
];

export const E_PROCEEDINGS_STATUS = [
  {
    label: 'Closed',
    value: EProceedingStatus.Closed,
  },
  {
    label: 'Pending',
    value: EProceedingStatus.Pending,
  },
  {
    label: 'Replied',
    value: EProceedingStatus.Replied,
  },
];

export const DURATION_TYPE = [
  {
    label: 'All',
    value: DurationType.All,
  },
  {
    label: 'Due Today',
    value: DurationType.DueToday,
  },
  {
    label: 'Over Dues',
    value: DurationType.OverDues,
  },
  {
    label: 'Last 24 Hours',
    value: DurationType.Last_24Hours,
  },
  {
    label: 'Due In 7 Days',
    value: DurationType.DueIn_7Days,
  },
  {
    label: 'Custom',
    value: DurationType.Custom,
  },
];

export const DURATION_REDIRECT_TYPE = [
  {
    label: 'allNotices',
    value: DurationType.All,
  },
  {
    label: 'dueToday',
    value: DurationType.DueToday,
  },
  {
    label: 'overDue',
    value: DurationType.OverDues,
  },
  {
    label: 'last24Hours',
    value: DurationType.Last_24Hours,
  },
  {
    label: '7DaysDue',
    value: DurationType.DueIn_7Days,
  },
  {
    label: 'Custom',
    value: DurationType.Custom,
  },
];

export const E_PROCEEDINGS_TYPE = [
  {
    label: 'Fyi',
    value: EProceedingType.Fyi,
  },
  {
    label: 'Fya',
    value: EProceedingType.Fya,
  },
];

export const MESSAGE = {
  required: 'This field is required',
};

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const COMPANY_INFO = { COOKIE_EXPIRY: 259200 };

export const defaultDateFormat = 'DD-MM-YYYY';
export const defaultTimeFormat = 'h:mmA';

export const AI_TOOLTIP_MESSAGE = 'Get your response with CounselVise AI';

export const ITR_FILL_START_YEAR = 1991;

export const EMPTY_STATE = { emptyText: 'There are no record to display' };
export const EMPTY_DUE_DATES_TEXT = { emptyText: 'No nearing due dates' };

export const DOWNLOAD_TEMPLATE_LINK =
  'https://noticeboard-static.dev.counselvise.com/sample-files/assessee/assessee-sample.csv';

export const FEATURE_NOTICE_BOARD_DIRECT_TAX = 'NOTICE_BOARD_DIRECT_TAX';
