import { useMutation } from '@apollo/client';
import { Button, Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { SelectDropdownIcon } from '../../../assets/svg';
import {
  ACTIVATION,
  ACTIVE_STATUS,
  MESSAGE,
  REGEX,
} from '../../../common/constants';
import { formItemProps, formValidatorRules } from '../../../common/utils';
import CommonModal from '../../../components/modals/CommonModal';
import { AssesseeCreateInput } from '../../../__generated__/graphql';
import { CreateAssesseeModalType } from '../graphql/clients.type';
import { CREATE_ASSESSEE, UPDATE_ASSESSEE } from '../graphql/Mutation';
const { required } = formValidatorRules;

const CreateAssesseeModal = ({
  open,
  footer,
  closable,
  onCancel,
  editData,
  onSuccess = () => {},
  onError = () => {},
}: CreateAssesseeModalType) => {
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);

  const [createAssessee, { loading: loadAddAssessee }] = useMutation(
    CREATE_ASSESSEE,
    {
      onError(error) {
        if (error) {
          onError(error?.message as string);
        }
      },
      onCompleted: (res) => {
        if (res) {
          form?.resetFields();
          onCancel();
          onSuccess(res?.createAssessee?.message as string);
        }
      },
    },
  );
  const [updateAssessee, { loading: loadUpdateAssessee }] = useMutation(
    UPDATE_ASSESSEE,
    {
      onError(error) {
        if (error) {
          onError(error?.message as string);
        }
      },
      onCompleted: (res) => {
        if (res) {
          form?.resetFields();
          onCancel();
          onSuccess(res?.updateAssessee?.message as string);
        }
      },
    },
  );

  useEffect(() => {
    if (editData) {
      form?.setFieldsValue({
        isActive: editData?.isActive
          ? ACTIVATION?.ACTIVE
          : ACTIVATION?.INACTIVE,
        username: editData?.username,
      });
    }
  }, [editData, form]);

  const checkFormValid = () => {
    const fieldsError = form?.getFieldsError();
    const hasErrors = fieldsError?.some(({ errors }) => errors.length > 0);
    const allFieldsTouched = form?.isFieldsTouched(true);
    setIsFormValid(!hasErrors && allFieldsTouched);
  };

  const onFinish = async (values: AssesseeCreateInput) => {
    if (editData?._id) {
      await updateAssessee({
        variables: {
          data: {
            isActive: status,
            password: values?.password,
          },
          where: { id: editData._id },
        },
      });
    } else {
      await createAssessee({
        variables: {
          data: { username: values?.username, password: values?.password },
        },
      });
    }
  };
  return (
    <>
      <CommonModal
        open={open}
        title={editData?._id ? 'Update Assessee' : 'Add Assessee'}
        footer={footer}
        closable={closable}
        onCancel={onCancel}
        maskClosable={false}
        centered={true}
      >
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          onFinish={onFinish}
          onFieldsChange={checkFormValid}
        >
          <Form.Item
            label="User Id"
            name="username"
            rules={[
              { ...required, message: MESSAGE?.required },
              {
                pattern: REGEX.PAN_NUMBER,
                message: 'Incorrect User Id',
              },
            ]}
            normalize={(value) => (value ?? '')?.toUpperCase()?.trim()}
          >
            <Input placeholder="PAN" disabled={!!editData?._id} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ ...required, message: MESSAGE?.required }]}
            normalize={formItemProps.normalize}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
          {editData?._id && (
            <>
              <Form.Item
                label="Status"
                name="isActive"
                rules={[{ ...required, message: MESSAGE?.required }]}
                normalize={formItemProps.normalize}
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  options={ACTIVE_STATUS}
                  placeholder="Change Status"
                  onChange={(value) => setStatus(value === 'active')}
                />
              </Form.Item>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className="full-width"
            disabled={!isFormValid}
            loading={loadAddAssessee || loadUpdateAssessee}
          >
            Submit
          </Button>
        </Form>
      </CommonModal>
    </>
  );
};

export default CreateAssesseeModal;
