/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation CreateAssessee($data: AssesseeCreateInput!) {\n  createAssessee(data: $data) {\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      createdAt\n      updatedAt\n      lastSyncAt\n      jurisdictionDetails\n    }\n    message\n  }\n}": types.CreateAssesseeDocument,
    "\n  mutation UpdateAssessee($where: AssesseeUniqueInput!, $data: AssesseeUpdateInput!) {\n  updateAssessee(where: $where, data: $data) {\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      createdAt\n      updatedAt\n      lastSyncAt\n      jurisdictionDetails\n      activityLogs {\n        _id\n        portal\n        pan\n        status\n        message\n        createdAt\n        updatedAt\n      }\n    }\n    message\n  }\n}": types.UpdateAssesseeDocument,
    "mutation DeleteAssessee($where: AssesseeUniqueIdsInput!) {\n  deleteAssessee(where: $where) {\n    message\n  }\n}": types.DeleteAssesseeDocument,
    "mutation SyncAssessee($where: AssesseeUniqueIdsInput!) {\n  syncAssessee(where: $where) {\n    message\n  }\n}": types.SyncAssesseeDocument,
    "query Assessees($sort: [AssesseesSort]!, $filter: AssesseesFilter) {\n  assessees(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      jurisdictionDetails\n      createdAt\n      updatedAt\n      lastSyncAt\n      activityLogs {\n        _id\n        portal\n        pan\n        status\n        message\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}": types.AssesseesDocument,
    "query SyncLogs($filter: SyncLogsFilter!, $sort: [SyncLogsSort]) {\n  syncLogs(filter: $filter, sort: $sort) {\n    count\n    data {\n      _id\n      portal\n      pan\n      status\n      message\n      createdAt\n      updatedAt\n      assessee {\n        _id\n        name\n        phoneNumber\n        loginStatus\n        username\n        password\n        isActive\n        createdBy\n        incorporateDate\n        address\n        jurisdictionDetails\n        createdAt\n        updatedAt\n        lastSyncAt\n      }\n    }\n  }\n}": types.SyncLogsDocument,
    "query Assessee($where: AssesseeUniqueInput!) {\n  assessee(where: $where) {\n    _id\n    name\n    phoneNumber\n    loginStatus\n    username\n    password\n    isActive\n    createdBy\n    incorporateDate\n    address\n    createdAt\n    updatedAt\n    lastSyncAt\n    jurisdictionDetails\n    activityLogs {\n      _id\n      portal\n      pan\n      status\n      message\n      createdAt\n      updatedAt\n    }\n  }\n}": types.AssesseeDocument,
    "query Clients($sort: [AssesseesSort]!, $filter: AssesseesFilter) {\n    assessees(sort: $sort, filter: $filter) {\n      data {\n        _id\n        username\n        name\n      }\n      count\n    }\n  }": types.ClientsDocument,
    "query EProceedings($filter: EProceedingsFilter) {\n    eProceedings(filter: $filter) {\n      count\n      data {\n        _id\n        name\n        assessmentYear\n        financialYear\n        issuedOn\n        responseDueDate\n        proceedingCloseDate\n        proceedingCloseOrder\n        proceedingLimitationDate\n        noticeUs\n        noticeReferenceId\n        documentRefId\n        description\n        proceedingOpenDate\n        type\n        remarks\n        remarkByAdmin\n        itrType\n        status\n        createdAt\n        updatedAt\n        assessee {\n          lastSyncAt\n          _id\n          username\n          name\n        }\n      }\n    }\n  }": types.EProceedingsDocument,
    "query ItfList($sort: [IncomeTaxFormsSort], $filter: IncomeTaxFormsFilter) {\n  itfList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      acknowledgmentNo\n      assessmentYear\n      filingDate\n    }\n  }\n}\n": types.ItfListDocument,
    "query ItrList($sort: [ItrListSort], $filter: ItrListFilter) {\n  itrList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      assessmentYear\n      acknowledgmentNo\n      filingDate\n    }\n  }\n}\n": types.ItrListDocument,
    "\n  mutation DisconnectCalender {\n  disconnectCalender {\n    message\n  }\n}": types.DisconnectCalenderDocument,
    "query AssesseeStats($where: AssesseeUniqueInput) {\n  assesseeStats(where: $where) {\n    allNotices\n    dueToday\n    last24Hours\n    openNotices\n    oneWeekDue\n    overDue\n    loginFailed\n    totalPan\n  }\n}": types.AssesseeStatsDocument,
    "query E_Proceedings($filter: EProceedingsFilter) {\n  eProceedings(filter: $filter) {\n    count\n    data {\n      _id\n      assessee {\n        _id\n        username\n        name\n      }\n      noticeReferenceId\n      assessmentYear\n      financialYear\n      responseDueDate\n      letterPdf {\n        attachments {\n          name\n          url\n        }\n      }\n      status\n      remarks\n    }\n  }\n}": types.E_ProceedingsDocument,
    "query EProceedingsCalenderCount($filter: EProceedingsCalenderCountFilter!) {\n  eProceedingsCalenderCount(filter: $filter) {\n    data\n  }\n}": types.EProceedingsCalenderCountDocument,
    "query CurrentUserCalender {\n    currentUserCalender {\n      type\n    }\n  }": types.CurrentUserCalenderDocument,
    "query ItrLists($sort: [ItrListSort], $filter: ItrListFilter) {\n  itrList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      assessmentYear\n      acknowledgmentNo\n      filingDate\n      attachments {\n        name\n        url\n      }\n      assessee {\n        _id\n        username\n        name\n      }\n      filedBy\n      filingType\n      forms {\n        name\n        url\n      }\n    }\n  }\n}\n": types.ItrListsDocument,
    "query SelectAssessees($filter: AssesseesFilter, $sort: [AssesseesSort]!) {\n  assessees(filter: $filter, sort: $sort) {\n    data {\n      username\n      _id\n      name\n    }\n    count\n  }\n}": types.SelectAssesseesDocument,
    "query Itr($where: ItrUniqueInput!) {\n  itr(where: $where) {\n    _id\n    assessmentYear\n    filingType\n    acknowledgmentNo\n    filedBy\n    itrType\n    filingDate\n    attachments {\n      name\n      url\n    }\n    metadata\n    createdAt\n    updatedAt\n    assessee {\n      _id\n      username\n      name\n    }\n  }\n}": types.ItrDocument,
    "query ItfLists($filter: IncomeTaxFormsFilter) {\n  itfList(filter: $filter) {\n    data {\n      _id\n      acknowledgmentNo\n      assessmentYear\n      financialYear\n      formNo\n      filingCount\n      filedBy\n      filingType\n      filingDate\n      status\n      name\n      formSubmittedDate\n      createdAt\n      updatedAt\n      attachments {\n        name\n        url\n      }\n      forms {\n        name\n        url\n      }\n      receipts {\n        name\n        url\n      }\n      assessee {\n        _id\n        username\n        name\n      }\n    }\n    count\n  }\n}": types.ItfListsDocument,
    "query Itf($where: ITFUniqueInput!) {\n  itf(where: $where) {\n    _id\n    assessee {\n      _id\n      username\n      name\n    }\n    acknowledgmentNo\n    assessmentYear\n    financialYear\n    formNo\n    filingCount\n    filedBy\n    filingType\n    filingDate\n    status\n    name\n    attachments {\n      name\n      url\n    }\n    forms {\n      name\n      url\n    }\n    receipts {\n      name\n      url\n    }\n    formSubmittedDate\n    createdAt\n    updatedAt\n\n  }\n}": types.ItfDocument,
    "query Query {\n  itfFilingTypes\n}": types.QueryDocument,
    "\n mutation UpdateEproceedingStatus($where: EProceedingUniqueIds!, $data: StatusUpdate!) {\n  updateEproceedingStatus(where: $where, data: $data) {\n    message\n  }\n}": types.UpdateEproceedingStatusDocument,
    "query EProceedingsList($filter: EProceedingsFilter) {\n  eProceedings(filter: $filter) {\n    data {\n      _id\n      assessee {\n        username\n        name\n      }\n      assessmentYear\n      financialYear\n      noticeReferenceId\n      name\n      issuedOn\n      responseDueDate\n      proceedingCloseDate\n      proceedingCloseOrder\n      proceedingLimitationDate\n      letterPdf {\n        from\n        attachments {\n          name\n          url\n        }\n      }\n      noticeUs\n      documentRefId\n      description\n      proceedingOpenDate\n      type\n      remarks\n      status\n      remarkByAdmin\n    }\n    count\n  }\n}\n": types.EProceedingsListDocument,
    "query EProceeding($where: EProceedingUniqueInput!) {\n    eProceeding(where: $where) {\n      _id\n      assessee {\n        name\n        username\n      }\n      name\n      financialYear\n      assessmentYear\n      documentRefId\n      noticeUs\n      description\n      letterPdf {\n        _id\n        cc\n        attachments {\n          name\n          url\n        }\n      }\n      status\n      type\n      responses {\n        _id\n        remarks\n        submittedOn\n        filedBy\n        type\n        attachments {\n          url\n          name\n        }\n      }\n    }\n  }\n": types.EProceedingDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateAssessee($data: AssesseeCreateInput!) {\n  createAssessee(data: $data) {\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      createdAt\n      updatedAt\n      lastSyncAt\n      jurisdictionDetails\n    }\n    message\n  }\n}"): (typeof documents)["\n  mutation CreateAssessee($data: AssesseeCreateInput!) {\n  createAssessee(data: $data) {\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      createdAt\n      updatedAt\n      lastSyncAt\n      jurisdictionDetails\n    }\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateAssessee($where: AssesseeUniqueInput!, $data: AssesseeUpdateInput!) {\n  updateAssessee(where: $where, data: $data) {\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      createdAt\n      updatedAt\n      lastSyncAt\n      jurisdictionDetails\n      activityLogs {\n        _id\n        portal\n        pan\n        status\n        message\n        createdAt\n        updatedAt\n      }\n    }\n    message\n  }\n}"): (typeof documents)["\n  mutation UpdateAssessee($where: AssesseeUniqueInput!, $data: AssesseeUpdateInput!) {\n  updateAssessee(where: $where, data: $data) {\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      createdAt\n      updatedAt\n      lastSyncAt\n      jurisdictionDetails\n      activityLogs {\n        _id\n        portal\n        pan\n        status\n        message\n        createdAt\n        updatedAt\n      }\n    }\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation DeleteAssessee($where: AssesseeUniqueIdsInput!) {\n  deleteAssessee(where: $where) {\n    message\n  }\n}"): (typeof documents)["mutation DeleteAssessee($where: AssesseeUniqueIdsInput!) {\n  deleteAssessee(where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation SyncAssessee($where: AssesseeUniqueIdsInput!) {\n  syncAssessee(where: $where) {\n    message\n  }\n}"): (typeof documents)["mutation SyncAssessee($where: AssesseeUniqueIdsInput!) {\n  syncAssessee(where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Assessees($sort: [AssesseesSort]!, $filter: AssesseesFilter) {\n  assessees(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      jurisdictionDetails\n      createdAt\n      updatedAt\n      lastSyncAt\n      activityLogs {\n        _id\n        portal\n        pan\n        status\n        message\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}"): (typeof documents)["query Assessees($sort: [AssesseesSort]!, $filter: AssesseesFilter) {\n  assessees(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      name\n      phoneNumber\n      loginStatus\n      username\n      password\n      isActive\n      createdBy\n      incorporateDate\n      address\n      jurisdictionDetails\n      createdAt\n      updatedAt\n      lastSyncAt\n      activityLogs {\n        _id\n        portal\n        pan\n        status\n        message\n        createdAt\n        updatedAt\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query SyncLogs($filter: SyncLogsFilter!, $sort: [SyncLogsSort]) {\n  syncLogs(filter: $filter, sort: $sort) {\n    count\n    data {\n      _id\n      portal\n      pan\n      status\n      message\n      createdAt\n      updatedAt\n      assessee {\n        _id\n        name\n        phoneNumber\n        loginStatus\n        username\n        password\n        isActive\n        createdBy\n        incorporateDate\n        address\n        jurisdictionDetails\n        createdAt\n        updatedAt\n        lastSyncAt\n      }\n    }\n  }\n}"): (typeof documents)["query SyncLogs($filter: SyncLogsFilter!, $sort: [SyncLogsSort]) {\n  syncLogs(filter: $filter, sort: $sort) {\n    count\n    data {\n      _id\n      portal\n      pan\n      status\n      message\n      createdAt\n      updatedAt\n      assessee {\n        _id\n        name\n        phoneNumber\n        loginStatus\n        username\n        password\n        isActive\n        createdBy\n        incorporateDate\n        address\n        jurisdictionDetails\n        createdAt\n        updatedAt\n        lastSyncAt\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Assessee($where: AssesseeUniqueInput!) {\n  assessee(where: $where) {\n    _id\n    name\n    phoneNumber\n    loginStatus\n    username\n    password\n    isActive\n    createdBy\n    incorporateDate\n    address\n    createdAt\n    updatedAt\n    lastSyncAt\n    jurisdictionDetails\n    activityLogs {\n      _id\n      portal\n      pan\n      status\n      message\n      createdAt\n      updatedAt\n    }\n  }\n}"): (typeof documents)["query Assessee($where: AssesseeUniqueInput!) {\n  assessee(where: $where) {\n    _id\n    name\n    phoneNumber\n    loginStatus\n    username\n    password\n    isActive\n    createdBy\n    incorporateDate\n    address\n    createdAt\n    updatedAt\n    lastSyncAt\n    jurisdictionDetails\n    activityLogs {\n      _id\n      portal\n      pan\n      status\n      message\n      createdAt\n      updatedAt\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Clients($sort: [AssesseesSort]!, $filter: AssesseesFilter) {\n    assessees(sort: $sort, filter: $filter) {\n      data {\n        _id\n        username\n        name\n      }\n      count\n    }\n  }"): (typeof documents)["query Clients($sort: [AssesseesSort]!, $filter: AssesseesFilter) {\n    assessees(sort: $sort, filter: $filter) {\n      data {\n        _id\n        username\n        name\n      }\n      count\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query EProceedings($filter: EProceedingsFilter) {\n    eProceedings(filter: $filter) {\n      count\n      data {\n        _id\n        name\n        assessmentYear\n        financialYear\n        issuedOn\n        responseDueDate\n        proceedingCloseDate\n        proceedingCloseOrder\n        proceedingLimitationDate\n        noticeUs\n        noticeReferenceId\n        documentRefId\n        description\n        proceedingOpenDate\n        type\n        remarks\n        remarkByAdmin\n        itrType\n        status\n        createdAt\n        updatedAt\n        assessee {\n          lastSyncAt\n          _id\n          username\n          name\n        }\n      }\n    }\n  }"): (typeof documents)["query EProceedings($filter: EProceedingsFilter) {\n    eProceedings(filter: $filter) {\n      count\n      data {\n        _id\n        name\n        assessmentYear\n        financialYear\n        issuedOn\n        responseDueDate\n        proceedingCloseDate\n        proceedingCloseOrder\n        proceedingLimitationDate\n        noticeUs\n        noticeReferenceId\n        documentRefId\n        description\n        proceedingOpenDate\n        type\n        remarks\n        remarkByAdmin\n        itrType\n        status\n        createdAt\n        updatedAt\n        assessee {\n          lastSyncAt\n          _id\n          username\n          name\n        }\n      }\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query ItfList($sort: [IncomeTaxFormsSort], $filter: IncomeTaxFormsFilter) {\n  itfList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      acknowledgmentNo\n      assessmentYear\n      filingDate\n    }\n  }\n}\n"): (typeof documents)["query ItfList($sort: [IncomeTaxFormsSort], $filter: IncomeTaxFormsFilter) {\n  itfList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      acknowledgmentNo\n      assessmentYear\n      filingDate\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query ItrList($sort: [ItrListSort], $filter: ItrListFilter) {\n  itrList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      assessmentYear\n      acknowledgmentNo\n      filingDate\n    }\n  }\n}\n"): (typeof documents)["query ItrList($sort: [ItrListSort], $filter: ItrListFilter) {\n  itrList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      assessmentYear\n      acknowledgmentNo\n      filingDate\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DisconnectCalender {\n  disconnectCalender {\n    message\n  }\n}"): (typeof documents)["\n  mutation DisconnectCalender {\n  disconnectCalender {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query AssesseeStats($where: AssesseeUniqueInput) {\n  assesseeStats(where: $where) {\n    allNotices\n    dueToday\n    last24Hours\n    openNotices\n    oneWeekDue\n    overDue\n    loginFailed\n    totalPan\n  }\n}"): (typeof documents)["query AssesseeStats($where: AssesseeUniqueInput) {\n  assesseeStats(where: $where) {\n    allNotices\n    dueToday\n    last24Hours\n    openNotices\n    oneWeekDue\n    overDue\n    loginFailed\n    totalPan\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query E_Proceedings($filter: EProceedingsFilter) {\n  eProceedings(filter: $filter) {\n    count\n    data {\n      _id\n      assessee {\n        _id\n        username\n        name\n      }\n      noticeReferenceId\n      assessmentYear\n      financialYear\n      responseDueDate\n      letterPdf {\n        attachments {\n          name\n          url\n        }\n      }\n      status\n      remarks\n    }\n  }\n}"): (typeof documents)["query E_Proceedings($filter: EProceedingsFilter) {\n  eProceedings(filter: $filter) {\n    count\n    data {\n      _id\n      assessee {\n        _id\n        username\n        name\n      }\n      noticeReferenceId\n      assessmentYear\n      financialYear\n      responseDueDate\n      letterPdf {\n        attachments {\n          name\n          url\n        }\n      }\n      status\n      remarks\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query EProceedingsCalenderCount($filter: EProceedingsCalenderCountFilter!) {\n  eProceedingsCalenderCount(filter: $filter) {\n    data\n  }\n}"): (typeof documents)["query EProceedingsCalenderCount($filter: EProceedingsCalenderCountFilter!) {\n  eProceedingsCalenderCount(filter: $filter) {\n    data\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query CurrentUserCalender {\n    currentUserCalender {\n      type\n    }\n  }"): (typeof documents)["query CurrentUserCalender {\n    currentUserCalender {\n      type\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query ItrLists($sort: [ItrListSort], $filter: ItrListFilter) {\n  itrList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      assessmentYear\n      acknowledgmentNo\n      filingDate\n      attachments {\n        name\n        url\n      }\n      assessee {\n        _id\n        username\n        name\n      }\n      filedBy\n      filingType\n      forms {\n        name\n        url\n      }\n    }\n  }\n}\n"): (typeof documents)["query ItrLists($sort: [ItrListSort], $filter: ItrListFilter) {\n  itrList(sort: $sort, filter: $filter) {\n    count\n    data {\n      _id\n      assessmentYear\n      acknowledgmentNo\n      filingDate\n      attachments {\n        name\n        url\n      }\n      assessee {\n        _id\n        username\n        name\n      }\n      filedBy\n      filingType\n      forms {\n        name\n        url\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query SelectAssessees($filter: AssesseesFilter, $sort: [AssesseesSort]!) {\n  assessees(filter: $filter, sort: $sort) {\n    data {\n      username\n      _id\n      name\n    }\n    count\n  }\n}"): (typeof documents)["query SelectAssessees($filter: AssesseesFilter, $sort: [AssesseesSort]!) {\n  assessees(filter: $filter, sort: $sort) {\n    data {\n      username\n      _id\n      name\n    }\n    count\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Itr($where: ItrUniqueInput!) {\n  itr(where: $where) {\n    _id\n    assessmentYear\n    filingType\n    acknowledgmentNo\n    filedBy\n    itrType\n    filingDate\n    attachments {\n      name\n      url\n    }\n    metadata\n    createdAt\n    updatedAt\n    assessee {\n      _id\n      username\n      name\n    }\n  }\n}"): (typeof documents)["query Itr($where: ItrUniqueInput!) {\n  itr(where: $where) {\n    _id\n    assessmentYear\n    filingType\n    acknowledgmentNo\n    filedBy\n    itrType\n    filingDate\n    attachments {\n      name\n      url\n    }\n    metadata\n    createdAt\n    updatedAt\n    assessee {\n      _id\n      username\n      name\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query ItfLists($filter: IncomeTaxFormsFilter) {\n  itfList(filter: $filter) {\n    data {\n      _id\n      acknowledgmentNo\n      assessmentYear\n      financialYear\n      formNo\n      filingCount\n      filedBy\n      filingType\n      filingDate\n      status\n      name\n      formSubmittedDate\n      createdAt\n      updatedAt\n      attachments {\n        name\n        url\n      }\n      forms {\n        name\n        url\n      }\n      receipts {\n        name\n        url\n      }\n      assessee {\n        _id\n        username\n        name\n      }\n    }\n    count\n  }\n}"): (typeof documents)["query ItfLists($filter: IncomeTaxFormsFilter) {\n  itfList(filter: $filter) {\n    data {\n      _id\n      acknowledgmentNo\n      assessmentYear\n      financialYear\n      formNo\n      filingCount\n      filedBy\n      filingType\n      filingDate\n      status\n      name\n      formSubmittedDate\n      createdAt\n      updatedAt\n      attachments {\n        name\n        url\n      }\n      forms {\n        name\n        url\n      }\n      receipts {\n        name\n        url\n      }\n      assessee {\n        _id\n        username\n        name\n      }\n    }\n    count\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Itf($where: ITFUniqueInput!) {\n  itf(where: $where) {\n    _id\n    assessee {\n      _id\n      username\n      name\n    }\n    acknowledgmentNo\n    assessmentYear\n    financialYear\n    formNo\n    filingCount\n    filedBy\n    filingType\n    filingDate\n    status\n    name\n    attachments {\n      name\n      url\n    }\n    forms {\n      name\n      url\n    }\n    receipts {\n      name\n      url\n    }\n    formSubmittedDate\n    createdAt\n    updatedAt\n\n  }\n}"): (typeof documents)["query Itf($where: ITFUniqueInput!) {\n  itf(where: $where) {\n    _id\n    assessee {\n      _id\n      username\n      name\n    }\n    acknowledgmentNo\n    assessmentYear\n    financialYear\n    formNo\n    filingCount\n    filedBy\n    filingType\n    filingDate\n    status\n    name\n    attachments {\n      name\n      url\n    }\n    forms {\n      name\n      url\n    }\n    receipts {\n      name\n      url\n    }\n    formSubmittedDate\n    createdAt\n    updatedAt\n\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query Query {\n  itfFilingTypes\n}"): (typeof documents)["query Query {\n  itfFilingTypes\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation UpdateEproceedingStatus($where: EProceedingUniqueIds!, $data: StatusUpdate!) {\n  updateEproceedingStatus(where: $where, data: $data) {\n    message\n  }\n}"): (typeof documents)["\n mutation UpdateEproceedingStatus($where: EProceedingUniqueIds!, $data: StatusUpdate!) {\n  updateEproceedingStatus(where: $where, data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query EProceedingsList($filter: EProceedingsFilter) {\n  eProceedings(filter: $filter) {\n    data {\n      _id\n      assessee {\n        username\n        name\n      }\n      assessmentYear\n      financialYear\n      noticeReferenceId\n      name\n      issuedOn\n      responseDueDate\n      proceedingCloseDate\n      proceedingCloseOrder\n      proceedingLimitationDate\n      letterPdf {\n        from\n        attachments {\n          name\n          url\n        }\n      }\n      noticeUs\n      documentRefId\n      description\n      proceedingOpenDate\n      type\n      remarks\n      status\n      remarkByAdmin\n    }\n    count\n  }\n}\n"): (typeof documents)["query EProceedingsList($filter: EProceedingsFilter) {\n  eProceedings(filter: $filter) {\n    data {\n      _id\n      assessee {\n        username\n        name\n      }\n      assessmentYear\n      financialYear\n      noticeReferenceId\n      name\n      issuedOn\n      responseDueDate\n      proceedingCloseDate\n      proceedingCloseOrder\n      proceedingLimitationDate\n      letterPdf {\n        from\n        attachments {\n          name\n          url\n        }\n      }\n      noticeUs\n      documentRefId\n      description\n      proceedingOpenDate\n      type\n      remarks\n      status\n      remarkByAdmin\n    }\n    count\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query EProceeding($where: EProceedingUniqueInput!) {\n    eProceeding(where: $where) {\n      _id\n      assessee {\n        name\n        username\n      }\n      name\n      financialYear\n      assessmentYear\n      documentRefId\n      noticeUs\n      description\n      letterPdf {\n        _id\n        cc\n        attachments {\n          name\n          url\n        }\n      }\n      status\n      type\n      responses {\n        _id\n        remarks\n        submittedOn\n        filedBy\n        type\n        attachments {\n          url\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["query EProceeding($where: EProceedingUniqueInput!) {\n    eProceeding(where: $where) {\n      _id\n      assessee {\n        name\n        username\n      }\n      name\n      financialYear\n      assessmentYear\n      documentRefId\n      noticeUs\n      description\n      letterPdf {\n        _id\n        cc\n        attachments {\n          name\n          url\n        }\n      }\n      status\n      type\n      responses {\n        _id\n        remarks\n        submittedOn\n        filedBy\n        type\n        attachments {\n          url\n          name\n        }\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;